/* #THEME COLOR (variable overrides)
========================================================================== */
$color-primary:						#6a7eb5;
$color-success:						#3caf16;
$color-info:						#2196F3;
$color-warning:						#ffc241;
$color-danger:						#d12b0d;
$color-fusion:						darken(desaturate(adjust-hue($color-primary, 5), 80%), 25%);

$nav-background-shade:              rgba(46,182,151,.18);

/* #GLOBAL IMPORTS
========================================================================== */
@import './src/scss/_imports/_theme-modules-import';

/* #Reset userselect
========================================================================== */
#myapp-0 {
	box-shadow: none !important;
}
#myapp-13 {
	box-shadow: 0 0 0 3px #000000;
}


